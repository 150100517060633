import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import { LocaleContext } from "../components/layout"
import DetailView from "../components/Templates/detailView";

const DabeiSein = ({ data: { content }, pageContext: { locale }, location }) => {
    const lang = React.useContext(LocaleContext)
    const i18n = lang.i18n[lang.locale]
    return (
        <>
            <SEO
             title={'Dabei sein?'}
             description={content.data.seo_dabei_sein_beschreibung.text}
             keywords={content.data.seo_dabei_sein_keywords.text ?content.data.seo_dabei_sein_keywords.text.split(', ') :
             [`Dabei sein?`]
           }
            />
            <DetailView img={content.data.dabei_sein_bild}>
            <section id="dabei-sein">
               <div className="container">
                <div className="content"
                    dangerouslySetInnerHTML={{
                        __html: content.data.dabei_sein.html,
                    }}
                    />
                </div>
                {/* <div dangerouslySetInnerHTML={{
                  __html: `<div style="text-align:center;">  </div> <script> (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm_share", b="https://embed.typeform.com/"; if(!gi.call(d,id)){ js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() </script>
                  `
                }} /> */}
            </section>
           
            

        </DetailView>
        </>
    )
}


export default DabeiSein


DabeiSein.propTypes = {
    data: PropTypes.shape({
        content: PropTypes.object.isRequired
    }).isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
         query DabeiSeinQuery($locale: String!) {
           content: prismicHomepage(lang: { eq: $locale }) {
             data {
               title {
                 text
               }
               dabei_sein {
                 html
               }
               seo_dabei_sein_beschreibung {
                text
              }
              seo_dabei_sein_keywords {
                text
              }
               bild {
                  alt
                  url
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
             }
           }
         }
       `
